import React, { useState } from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import ContactFrom from "../../components/contactForm";
import Map from "../../components/map";

const Contact = () => {
  const lang = "de";
  const [activeDepartment, setActiveDepartment] = useState("1");

  return (
    <Layout
      seoTitle="Kontakt"
      lang={lang}
      translationPL="/kontakt/"
      translationEN="/en/contact-us/"
    >
      <SmallBanner title="Kontakt" lang={lang} />
      <section className="contact-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Angaben zur Firma</h3>

              <div className="row">
                <div className="col-sm-6">
                  <address>
                    Palettenwerk Kozik Sp. z o.o. <br />
                    ul. Przemysłowa 219 <br />
                    34-240 Jordanów
                  </address>
                </div>
                <div className="col-sm-6">
                  <address>
                    NIP: 944-224-48-96 <br />
                    KRS: 0000966492 <br />
                    REGON: 122861621
                  </address>
                </div>
                <div className="col-sm-6">
                  <address>
                    PALKO sp. z o.o. <br />
                    Gniewięcin 190 <br />
                    28-340 Sędziszów
                  </address>
                </div>
                <div className="col-sm-6">
                  <address>
                    NIP: 735-002-69-43 <br />
                    KRS: 0000118040 <br />
                    REGON: 490443717
                  </address>
                </div>
              </div>
              <h3>Abteilungen</h3>
              <div className="row">
                <div className="col-sm-6">
                  <div className="contact-main--departments">
                    <button
                      onClick={() => setActiveDepartment("1")}
                      className={activeDepartment === "1" ? "current" : ""}
                    >
                      Sekretariat
                    </button>
                    <button
                      onClick={() => setActiveDepartment("2")}
                      className={activeDepartment === "2" ? "current" : ""}
                    >
                      Verkauf und Kundendienst
                    </button>
                    <button
                      onClick={() => setActiveDepartment("3")}
                      className={activeDepartment === "3" ? "current" : ""}
                    >
                      Beschaffung von Schnittholz
                    </button>
                    <button
                      onClick={() => setActiveDepartment("4")}
                      className={activeDepartment === "4" ? "current" : ""}
                    >
                      Betriebsinstandhaltung
                    </button>
                    <button
                      onClick={() => setActiveDepartment("5")}
                      className={activeDepartment === "5" ? "current" : ""}
                    >
                      Logistik
                    </button>
                    <button
                      onClick={() => setActiveDepartment("6")}
                      className={activeDepartment === "6" ? "current" : ""}
                    >
                      Buchhaltung
                    </button>
                  </div>
                </div>
                <div className="col-sm-6" id="departments">
                  {activeDepartment === "1" ? (
                    <div className="contact-main--info">
                      <p>Hauptsitz Jordanów:</p>
                      <a href="tel:+48182773530">+48 18 2773 530</a>
                      <a href="mailto:biuro@palettenwerk.pl">
                        biuro@palettenwerk.pl
                      </a>
                      <p>Filiale Sędziszów:</p>
                      <a href="mailto:palko@palettenwerk.pl">
                        palko@palettenwerk.pl
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeDepartment === "2" ? (
                    <div className="contact-main--info">
                      <a href="tel:+48182773535">+48 18 2773 535</a>
                      <a href="mailto:palety@palettenwerk.pl">
                        palety@palettenwerk.pl
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeDepartment === "3" ? (
                    <div className="contact-main--info">
                      <p>Hauptsitz Jordanów:</p>
                      <a href="tel:+48182773536">+48 18 2773 536</a>
                      <p>Filiale Sędziszów:</p>
                      <a href="mailto:zakupy@palettenwerk.pl">
                        zakupy@palettenwerk.pl
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeDepartment === "4" ? (
                    <div className="contact-main--info">
                      <p>Hauptsitz Jordanów:</p>
                      <a href="tel:+48182773537">+48 18 2773 537</a>
                      <p>Hauptsitz Jordanów:</p>
                      <a href="mailto:ur@palettenwerk.pl">ur@palettenwerk.pl</a>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeDepartment === "5" ? (
                    <div className="contact-main--info">
                      <a href="tel:+48182773532">+48 18 2773 532</a>
                      <a href="mailto:logistyka@palettenwerk.pl">
                        logistyka@palettenwerk.pl
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeDepartment === "6" ? (
                    <div className="contact-main--info">
                      <p>Hauptsitz Jordanów:</p>
                      <a href="tel:+48182773533">+48 18 2773 533</a>
                      <a href="mailto:ksiegowosc@palettenwerk.pl">
                        ksiegowosc@palettenwerk.pl
                      </a>
                      <p>Hauptsitz Sędziszów:</p>
                      <a href="mailto:ksiegowosc.palko@palettenwerk.pl">
                        ksiegowosc.palko@palettenwerk.pl
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h3>Kontaktformular</h3>
              <ContactFrom lang={lang} />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <Map lang={lang} />
      </section>
    </Layout>
  );
};

export default Contact;
